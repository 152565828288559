<template>
  <time class="base-date" :datetime="date">
    <span>{{ day }} </span>
    <span v-if="showTime">{{ time }}</span>
  </time>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'BaseDate',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    date: [Number, String],
    showYear: Boolean,
    showTime: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    day() {
      const format = this.showYear ? 'DD.MM.YYYY' : 'DD.MM';
      return dayjs(this.date).format(format);
    },
    time() {
      return dayjs(this.date).format('HH:mm');
    },
  },
};
</script>

<style lang="scss">
  .base-date {
    font-size: 12px;
    line-height: 16px;
  }
</style>
