<template>
  <article class="article-item">
    <div class="article-item__main">
      <div class="block block--white block--rounded">
        <div v-if="createDate" class="article-item__create-date">
          <span>Дата создания</span>

          <base-date
            :date="createDate"
            :show-time="false"
            show-year
          />
        </div>

        <h2 class="article-item__title">
          {{ title }}
        </h2>

        <picture
          v-if="mainImageUrl"
          class="article-item__image"
          ref="imageWrapper"
        >
          <img
            :src="mainImageUrl"
            :alt="title"
            @error="onImageLoadError"
          >
        </picture>

        <div class="article-item__content" v-html="content" />
      </div>

      <div v-if="attachments.length" class="block block--white block--rounded">
        <h2 class="article-item__sub-title">
          Вложения
        </h2>

        <div class="tile-grid">
          <attachment-item
            v-for="attachment in attachments"
            :key="`article-attachment-${attachment.id}`"
            :file="attachment"
          />
        </div>
      </div>
    </div>

    <aside class="article-item__side">
      <slot name="side" />
    </aside>
  </article>
</template>

<script>
import BaseDate from '@/components/base/base-date';
import AttachmentItem from '@/pages/Ticket/components/AttachmentItem.vue';

export default {
  name: 'ArticleItem',

  components: {
    AttachmentItem,
    BaseDate,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    content: {
      type: String,
      required: true,
    },

    createDate: {
      type: [String, Number],
      default: null,
    },

    mainImageUrl: {
      type: String,
      default: null,
    },

    attachments: {
      type: Array,
      default: () => ([]),
    },
  },

  methods: {
    onImageLoadError() {
      this.$refs.imageWrapper.style.display = 'none';
    },
  },
};
</script>

<style lang="scss">
.article-item {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  @include for-size(phone-landscape-down) {
    flex-direction: column;
  }

  @include for-size(phone-portrait-down) {
    width: 100vw;
    margin-left: -20px;
  }

  &__main {
    flex-grow: 1;

    .block {
      padding: 32px;

      @include for-size(phone-portrait-down) {
        padding: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &__side {
    width: $right-column-width;
    flex-shrink: 0;
    margin-left: $gap;

    @include for-size(tablet-portrait-down) {
      width: math.div($right-column-width, 2);
    }

    @include for-size(phone-portrait-down) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__create-date {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: $color-grayscale-40;
    margin-bottom: 8px;

    .base-date {
      margin-left: 5px;
    }
  }

  &__title {
    margin-bottom: 32px;
  }

  &__sub-title {
    margin-bottom: $base-gutter;
  }

  img {
    // С сервера в статьях чаще всего приходят картинки с указанием ширины и высоты,
    // поэтому оставляем картинку такой, чтобы она просто не вылезала за края статьи
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__image {
    position: relative;
    height: 25vw;
    margin-bottom: 16px;
    border-radius: $base-border-radius;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-size(phone-portrait-down) {
      height: auto;
      width: 100%;
    }
  }

  &__content {
    p {
      margin-bottom: 20px;
    }
  }

  &__content-item {
    margin-bottom: 20px;
  }
}
</style>
