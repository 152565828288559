<template>
  <div
    v-if="file"
    class="attachment"
    :title="`Скачать файл ${fileName || ''}`"
    @click="downloadFile"
  >
    <img
      class="attachment__file-image"
      :src="file.url"
      :alt="fileName"
      v-if="isShowImage && file.format === 'image' && isOpenedImage"
    >
    <div class="attachment__inner">
      <div class="attachment__file-preview">
        <esmp-icon
          clas="attachment__file-icon"
          :name="file.format"
        />
      </div>

      <div class="attachment__file-data">
        <div class="attachment__file-name">
          <span>{{ fileName | truncate(truncateItemTitleSize) }}</span>
        </div>

        <div class="attachment__file-size">
          {{ file.extension | upperCase }} • {{ file.size | humanFileSizes }}  • {{ file.date | dateFormat }}
        </div>
      </div>
      <esmp-button
        view="function"
        v-if="isShowImage && file.format === 'image'"
        @click.stop="toggleImage"
      >
        {{ isOpenedImage ? 'Скрыть' : 'Показать' }}
      </esmp-button>
      <span v-if="withDeleteButton" @click="$emit('remove', file.name)">
        <esmp-icon
          name="trash"
          class="attachment__file-remove"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
// TODO: перенести компонент в общую папку, используется в статьях

export default {
  name: 'AttachmentItem',

  props: {
    file: {
      type: [Object, File],
      default: undefined,
      // TODO: этот пропс должен быть обязательным, иначе смысл от компонента :)
      // TODO: лучше разбить этот пропс на составляющие (name, size, extension, ...)
    },

    withDeleteButton: {
      type: Boolean,
      default: false,
    },

    truncateItemTitleSize: {
      type: Number,
      default: 40,
    },

    isShowImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenedImage: false,
    };
  },

  computed: {
    fileName() {
      return this.file.originalFilename || this.file.name || '';
    },
  },

  methods: {
    downloadFile() {
      if (this.file.url) {
        saveAs(this.file.url, this.fileName);
      }
    },
    toggleImage() {
      this.isOpenedImage = !this.isOpenedImage;
    },
  },
};
</script>

<style lang="scss">
.attachment {
  color: #101828;
  transition: background-color 0.2s ease-in-out;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 16px 0 0 0;
  cursor: pointer;
  &:first-child {
    margin: 0;
  }
  &:before {
    display: none;
  }
  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__file-data {
    flex: 1;
    padding: 5px 16px 5px 0;
  }
  &__file-preview {
    width: 40px;
    height: 40px;
    margin: 0 16px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(16, 24, 40, 0.05);
    border-radius: 12px;
  }
  &__file-icon {
    width: 20px;
  }
  &__file-image {
    width: 100%;
  }
  &__file-name, &__file-size {
    font-size: 12px;
    line-height: 16px;
  }
  &__file-size {
    color: rgba(181, 183, 192, 1);
  }
  &__file-remove {
    width: 16px;
    height: 18px;
    cursor: pointer;
    float: right;
    color: #999;
    transition: all 0.2s ease;
    &:hover {
      color: #444;
    }
  }
}
</style>
